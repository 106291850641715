import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Col, DeleteButton, Divider, EditButton, Icons, Row, Show, Tabs, Typography } from "@pankod/refine-antd";
import { CanAccess, IResourceComponentsProps, useNavigation, useOne, useShow } from "@pankod/refine-core";
import { HistoryTab } from "components/common/historyTab";
import { StateButtons } from "components/common/stateButtons";
import { ShowUserCard } from "components/profile/showUserCard";
import { LookupButton } from "components/utils/lookupButton";
import { ObjectMetadata } from "components/utils/objectMetadata";
import { slaDeadline } from "components/utils/slaDeadline";
import { IBaseProps, ICase, ICaseMetadata } from "interfaces";
import { DATAPROVIDER_DELETE, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_STATE, RESOURCE_PATH } from "scripts/site";
const { Title, Text, Paragraph } = Typography;

export const CaseShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<ICase>({
    dataProviderName: DATAPROVIDER_READ
  });

  const { data, isLoading } = queryResult;
  const record = data?.data;
  const { data: entityMetadata } = useOne<ICaseMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.CASE,
    queryOptions: {
      enabled: true,
      staleTime: 300000
    }
  });
  const metaConfig = entityMetadata?.data?.config;
  const { list } = useNavigation();

  return (
    <>
      <Show
        isLoading={isLoading}
        canDelete={false}
        canEdit={false}

        headerProps={{
          extra: <>
            {slaDeadline(metaConfig?.sla, record?.stateManager)}
            {
              record ?
                <StateButtons {...record as IBaseProps}
                  resourceToAct={RESOURCE_PATH.CASE}
                  resourceToActId={record?.id || "0"}
                  navigateToId={""}
                  resourceToNavigate={RESOURCE_PATH.CASE}
                  hideActions={[]}
                  fetchAssignedToList={false}
                  skipAssignedToCheck={true}
                  dataProviderName={DATAPROVIDER_STATE}
                /> :
                <></>
            }
            <CanAccess resource={RESOURCE_PATH.CASE.toLowerCase()} action="edit">
              <EditButton></EditButton>
            </CanAccess>
            <CanAccess resource={RESOURCE_PATH.CASE.toLowerCase()} action="delete">
              <DeleteButton
                dataProviderName={DATAPROVIDER_DELETE}
                onSuccess={() => {
                  list(RESOURCE_PATH.CASE, "replace")
                }}
              ></DeleteButton>
            </CanAccess>
          </>,
        }}
      >
        <Tabs defaultActiveKey="details">
          <Tabs.TabPane key="details" tab={
            <>
              <Icons.InfoOutlined />
              Details
            </>
          }>
            <Row gutter={[16, 16]}>
              <Col xl={12} xs={24}>
                <Title level={5}>Case Title</Title>
                <Text>{record?.name}</Text>

                <Title level={5}>Case Id</Title>
                <Text copyable >{record?.id}</Text>

                <Title level={5} style={{ marginTop: 15 }}>State</Title>
                <Text>{stringExtensions.capitalize(record?.stateManager?.state ?? "")}</Text>

                <Title level={5} style={{ marginTop: 15 }}>Assigned To</Title>
                {record?.stateManager?.assignedTo?.length !== 0 ? <ShowUserCard id={record?.stateManager?.assignedTo[0]} /> : <Text>---</Text>}

                <Title level={5} style={{ marginTop: 15 }}>Notes</Title>
                <Paragraph>{record?.notes ?? "---"}</Paragraph>
              </Col>
              <Col xl={12} lg={24} xs={24}>
                <Title level={5} style={{ marginTop: 15 }}>Business Function</Title>
                <LookupButton id={record?.businessFunctionId || ""} resource={RESOURCE_PATH.BUSINESS_FUNCTION}></LookupButton>

                <Title level={5} style={{ marginTop: 15 }}>Line of Business</Title>
                <LookupButton id={record?.lineOfBusinessId || ""} resource={RESOURCE_PATH.LINE_OF_BUSINESS}></LookupButton>

                <Title level={5} style={{ marginTop: 15 }}>Program</Title>
                <LookupButton id={record?.programId || ""} resource={RESOURCE_PATH.PROGRAM}></LookupButton>

                <Title level={5} style={{ marginTop: 15 }}>Case Type</Title>
                <LookupButton id={record?.caseTypeId || ""} resource={RESOURCE_PATH.CASE_TYPE}></LookupButton>

                <Title level={5} style={{ marginTop: 15 }}>Sub Case Type</Title>
                <LookupButton id={record?.subCaseTypeId || ""} resource={RESOURCE_PATH.SUB_CASE_TYPE}></LookupButton>

                <Title level={5} style={{ marginTop: 15 }}>Case Reason</Title>
                <LookupButton id={record?.caseReasonId || ""} resource={RESOURCE_PATH.CASE_REASON}></LookupButton>

                <Title level={5} style={{ marginTop: 15 }}>Sub Reason</Title>
                <LookupButton id={record?.subReasonId || ""} resource={RESOURCE_PATH.SUB_REASON}></LookupButton>
              </Col>
            </Row>
            <Divider></Divider>
            <div style={{ marginTop: 15 }}>
              <ObjectMetadata {...record as IBaseProps}></ObjectMetadata>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane key="history" tab={
            <>
              <Icons.HistoryOutlined />
              History
            </>
          }>
            {record && <HistoryTab stateManager={record.stateManager} tabPosition={'left'} />}
          </Tabs.TabPane>
        </Tabs>

      </Show>
    </>
  );
};
