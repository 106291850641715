import {
  arrayExtensions,
  stringExtensions
} from "@architecture-innovation-transformation/lib-common";
import { useTable } from "@pankod/refine-antd";
import { BaseRecord } from "@pankod/refine-core";
import { ai } from "appInsightLoad";
import { Mutex } from "async-mutex";
import dayjs from "dayjs";
import {
  IAuthZ,
  IEvaluationModel,
  IGroup,
  IOption,
  IState,
  IStateManager,
  nextState
} from "interfaces";
import { useEffect, useState } from "react";

export const API_PATH = "/api/v1";
export const AUTH_ME = "/.auth/me";
export const MY_PROFILE = API_PATH + "/my/profile";
const AUTHZ = API_PATH + "/authz/metadata";
const ATTACHMENT = API_PATH + "/attachment";

export const API_LOOKUP = "/lookup";
export const API_SYSTEM = "/system";
export const API_CRUD = "/crud";
export const API_READ = "/read";
export const API_CREATE = "/create";
export const API_UPDATE = "/update";
export const API_DELETE = "/delete";
export const API_STATE = "/state";
export const API_MY = "/my";
export const API_AUTHZ = "/authz";
export const API_UPLOAD = "/upload";
export const API_SUBSCRIBE = "/subscribe";
export const API_OUTREACH = "/outreach";

export const DATAPROVIDER_AUTHZ = "authz";
export const DATAPROVIDER_LOOKUP = "lookup";
export const DATAPROVIDER_SYSTEM = "system";
export const DATAPROVIDER_DEFAULT = "default";
export const DATAPROVIDER_READ = "read";
export const DATAPROVIDER_CREATE = "create";
export const DATAPROVIDER_UPDATE = "update";
export const DATAPROVIDER_DELETE = "delete";
export const DATAPROVIDER_STATE = "state";
export const DATAPROVIDER_UPLOAD = "upload";
export const DATAPROVIDER_SUBSCRIBE = "subscribe";
export const DATAPROVIDER_OUTREACH = "outreach";

export const EVALUATIONMODEL_AGGREGATION_AVERAGE = "avg";
export const EVALUATIONMODEL_AGGREGATION_MIN = "min";
export const EVALUATIONMODEL_AGGREGATION_MAX = "max";

// Milliseconds
export const CACHE_DURATION = 60000;
export const CACHE_KEY_PREFIX = "Helium";
export const STATE_ACTION = "state";
export const FETCH_ACTION = "fetch";

// Authentication and Authorization
export const AAD_LOGIN = "/.auth/login/aad";
export const AAD_LOGOUT = "/.auth/logout";

// Helium Logo
export const HELIUM_LOGO_PATH = "/helium-logo-1.png";

export const RESOURCE_PATH = {
  ABOUT: "about",
  ASSESSMENT: "assessment",
  ASSET: "asset",
  CONTRACT: "contract",
  ASSOCIATEMAPPING: "associatemapping",
  DASHBOARD: "dashboard",
  ENGAGEMENTS: "engagement",
  ENGAGEMENTTYPE: "engagementtype",
  EVALUATION: "evaluation",
  EVALUATIONMODEL: "evaluationmodel",
  LOCATION: "location",
  METADATA: "metadata",
  NOTIFICATIONS: "notification",
  PROFILE: "profile",
  PROJECT: "project",
  ROLE: "role",
  SYSTEM: "system",
  USERS: "user",
  GROUPS: "group",
  WORKLIST: "worklist",
  EVENT: "event",
  INFO_SECURITY: "infosecurity",
  INFO_ASPIRE: "infoaspire",
  INFO_ANNOUNCEMENTS: "infoannouncements",
  INFO_ABMDESK: "infoabmdesk",
  EXPERT_OUTREACH: "expertoutreach",
  LIST: "list",
  REVIEW_TAGS: "tagsreview",
  BUSINESS_FUNCTION: "businessfunction",
  LINE_OF_BUSINESS: "lineofbusiness",
  PROGRAM: "program",
  CASE_TYPE: "casetype",
  SUB_CASE_TYPE: "subcasetype",
  CASE_REASON: "casereason",
  SUB_REASON: "subreason",
  CASE: "case",
};

export const SELECTION_LISTS = {
  HOBBIES: "hobbies",
  TECHNICAL_EXPERTISE: "technicalexpertise",
  FUNCTIONAL_EXPERTISE: "functionalexpertise",
  TECHNICAL_CERTIFICATIONS: "technicalcertificates",
  FUNCTIONAL_CERTIFICATIONS: "functionalcertificates",
};

export const allowedResources: string[] = [];

const actionMap = [
  {
    api: "get",
    web: "list",
  },
  {
    api: "get",
    web: "show",
  },
  {
    api: "post",
    web: "create",
  },
  {
    api: "delete",
    web: "delete",
  },
  {
    api: "patch",
    web: "edit",
  },
];

export function webToApiAction(webAction: string): string {
  return (
    actionMap.find((m) => stringExtensions.stringEquals(m.web, webAction))
      ?.api ?? webAction
  );
}

export function apiToWebAction(apiAction: string): string {
  return (
    actionMap.find((m) => stringExtensions.stringEquals(m.api, apiAction))
      ?.web ?? apiAction
  );
}

// Local Storage - Session Storage
export function removeLocalSessionStorage(key: string) {
  sessionStorage.removeItem(CACHE_KEY_PREFIX + key);
}

export function clearLocalSessionStorage(all: boolean = true) {
  if (all) {
    sessionStorage.clear();
  } else {
    [...Array(sessionStorage.length)]
      .map((_, i) => sessionStorage.key(i))
      .filter((key) => key?.startsWith(CACHE_KEY_PREFIX))
      .forEach((key) => (key ? sessionStorage.removeItem(key) : () => { }));
  }
  ai.clearAuthenticatedUserContext();
}

export function setSessionStorageWithExpiry(
  key: string,
  value: any,
  ttl: number
) {
  const cacheKey = CACHE_KEY_PREFIX + key;
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  sessionStorage.setItem(cacheKey, JSON.stringify(item));
}

export function getSessionStorageWithExpiry(key: string) {
  const cacheKey = CACHE_KEY_PREFIX + key;
  const itemStr = sessionStorage.getItem(cacheKey);

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    sessionStorage.removeItem(cacheKey);
    return null;
  }
  return item.value;
}

// Local Storage - Outside Session
export function removeLocalStorage(key: string) {
  localStorage.removeItem(CACHE_KEY_PREFIX + key);
}

export function clearLocalStorage(all: boolean = true) {
  if (all) {
    localStorage.clear();
  } else {
    [...Array(localStorage.length)]
      .map((_, i) => localStorage.key(i))
      .filter((key) => key?.startsWith(CACHE_KEY_PREFIX))
      .forEach((key) => (key ? localStorage.removeItem(key) : () => { }));
  }
}

export function setLocalStorage(key: string, value: any) {
  const cacheKey = CACHE_KEY_PREFIX + key;
  const now = new Date();
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    timeStamp: Math.floor(now.getTime() / 1000),
  };
  localStorage.setItem(cacheKey, JSON.stringify(item));
}

export function getLocalStorage(key: string, checkTimeStamp?: number) {
  const cacheKey = CACHE_KEY_PREFIX + key;
  const itemStr = localStorage.getItem(cacheKey);

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  // compare the expiry time of the item with the current time
  if (checkTimeStamp && item.timeStamp < checkTimeStamp) {
    // If the item in local storage is modified later then retreived
    localStorage.removeItem(cacheKey);
    return null;
  }
  return item.value;
}

export function syncWait(ms: number) {
  const end = Date.now() + ms;
  while (Date.now() < end) {
    continue;
  }
}

export type resParser = (apiRes: any) => any;
const defResParser = (apiRes: any) => apiRes;

const mutexList = new Map<string, Mutex>();

export async function apiCallWithMutex(
  apiPath: string,
  resonseParser: resParser = defResParser,
  dontUseCache = false
) {
  let clientLock = mutexList.get(apiPath);
  if (!clientLock) {
    clientLock = new Mutex();
    mutexList.set(apiPath, clientLock);
  }

  let release = await clientLock.acquire();
  const result = await apiCallWithLocalCache(
    apiPath,
    resonseParser,
    dontUseCache
  );
  release();
  return result;
}

export async function apiCallWithLocalCache(
  apiPath: string,
  resonseParser: resParser = defResParser,
  dontUseCache = false
) {
  if (dontUseCache) {
    sessionStorage.removeItem(CACHE_KEY_PREFIX + apiPath);
  } else {
    // Check local storage
    const cachedResponse = getSessionStorageWithExpiry(apiPath);
    if (cachedResponse) {
      //console.log("Serving from Cache - " + apiPath);
      return cachedResponse;
    }
  }

  // Item not found in cache
  // console.log("Hitting API - " + apiPath);
  const response = await fetch(apiPath, {
    method: "GET",
    headers: {
      "x-helium-user-time": new Date().toString(),
    },
  });
  if (response.ok) {
    const resJson = resonseParser(await response.json());
    // Store the response in cache for 1 min if its not null.
    if (resJson && !dontUseCache) {
      //console.log("Storing in Cache - " + apiPath);
      setSessionStorageWithExpiry(apiPath, resJson, CACHE_DURATION);
    }
    return resJson;
  }
  return null;
}

export async function fetchClientPrincipal() {
  // Retrieve response from /.auth/me
  // Retrieve the clientPrincipal (current user)
  // Skip Cache for all auth me calls

  const clientPrincipal = await apiCallWithMutex(
    AUTH_ME,
    (apiRes) => apiRes.clientPrincipal
  );
  if (clientPrincipal) {
    ai.setAuthenticatedUserContext(
      clientPrincipal.userId,
      clientPrincipal.userDetails.split("@")[1],
      true
    );
  } else {
    clearLocalSessionStorage(false);
  }
  return clientPrincipal;
}

export async function fetchAuthZ(): Promise<IAuthZ[]> {
  return await apiCallWithMutex(AUTHZ);
}

export async function fetchUserIdentity() {
  return await apiCallWithMutex(MY_PROFILE);
}

export function matchRbac(
  permission: string[],
  resource: string,
  action: string
): boolean {
  if (arrayExtensions.validateArray(permission)) {
    return permission.some((perm) => {
      // Split the perm array by /. Should have exactly 3 parts.
      const permArr = perm.split("/");
      if (permArr && permArr.length === 3) {
        const entity = permArr[0];
        const scope = permArr[1];
        const capability = permArr[2];

        return (
          (entity === "*" || stringExtensions.stringEquals(resource, entity)) &&
          scope === "*" && // implement scope level logic here
          (capability === "*" ||
            stringExtensions.stringEquals(action, capability))
        );
      }
      return false;
    });
  }
  return false;
}

export function getQueryStringParams(query: string) {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
      .split("&")
      .reduce((params: any, param) => {
        let [key, value] = param.split("=");
        params[key] = value
          ? decodeURIComponent(value.replace(/\+/g, " "))
          : "";
        return params;
      }, {})
    : {};
}

export function calculateScore(record: IEvaluationModel) {
  if (record) {
    record.groups.forEach((grp) => {
      grp.questions.forEach((ques) => {
        let checkedOptions = ques.multiSelect
          ? ques.options.filter((o) => o.checked)
          : ques.options.filter((o) => o.name === ques.selectedOption);

        if (
          (ques.multiSelect && checkedOptions.length > 0) ||
          (!ques.multiSelect && checkedOptions.length === 1)
        ) {
          const checkedSum = checkedOptions
            .map((i) => i.weightage ?? 1)
            .reduce((partialSum, a) => partialSum + a, 0);

          const total = ques.multiSelect
            ? ques.options
              .map((i) => i.weightage ?? 1)
              .reduce((partialSum, a) => partialSum + a, 0)
            : Math.max(...ques.options.map((i) => i.weightage ?? 1));

          ques.score = checkedSum / total;
        } else {
          ques.score = 0;
        }
      });

      const checkedSum = grp.questions
        .map((i) => (i.score ?? 0) * (i.weightage ?? 1))
        .reduce((partialSum, a) => partialSum + a, 0);

      const total = grp.questions
        .map((i) => i.weightage ?? 1)
        .reduce((partialSum, a) => partialSum + a, 0);
      grp.score = checkedSum / total;
    });

    if (!record.aggregation) {
      record.aggregation = EVALUATIONMODEL_AGGREGATION_AVERAGE;
    }

    record.output.maturityLevel = getMaturityLevel(record);

    switch (record.aggregation) {
      case EVALUATIONMODEL_AGGREGATION_AVERAGE:
        const checkedSum = record.groups
          .map((i) => (i.score ?? 0) * (i.weightage ?? 1))
          .reduce((partialSum, a) => partialSum + a, 0);

        const total = record.groups
          .map((i) => i.weightage ?? 1)
          .reduce((partialSum, a) => partialSum + a, 0);

        record.output.score = checkedSum / total;
        break;
      case EVALUATIONMODEL_AGGREGATION_MAX:
        record.output.score = Math.max(
          ...record.groups.map((i) => i.score ?? 1)
        );
        break;
      case EVALUATIONMODEL_AGGREGATION_MIN:
        record.output.score = Math.min(
          ...record.groups.map((i) => i.score ?? 1)
        );
        break;
      default:
        break;
    }
  }
}

export function getMaturityLevel(record: IEvaluationModel): string {
  var level = "";
  if (record && record.groups.length > 0) {
    // Consider the maturity at base level to start with
    var maturityIndex = 0;
    for (var i = 0; i < record.groups.length; i++) {
      // At any particular level, score should be minimum Qualifying Score, if not return back
      if (record.groups[i].score < record.minQualifyingScore) {
        break;
      } else {
        maturityIndex = i;
      }
    }

    level = record.groups[maturityIndex].name;
  }
  return level;
}

export function useAttachment(url: string | undefined) {
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    url &&
      fetch(url + "&redirect=false")
        .then((res) => (res.ok ? res.json() : { url: "" }))
        .then((json) => {
          if (json && json.url) {
            fetch(json.url)
              .then((res) => (res.ok ? res.text() : ""))
              .then((text) => {
                setIsLoading(false);
                setResponse(text);
              });
          } else {
            setIsLoading(false);
            setResponse("# Not available");
          }
        })
        .catch(() => {
          setIsLoading(false);
          setResponse("# Not available");
        });
  }, [url]);
  return { response, isLoading };
}

export function useUrlFromBlob(url: string | undefined) {
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    url &&
      fetch(url + "&redirect=false")
        .then((res) => (res.ok ? res.json() : { url: "" }))
        .then((json) => {
          if (json && json.url) {
            setResponse(json.url);
          } else {
            setIsLoading(false);
            setResponse("# Not available");
          }
        })
        .catch(() => {
          setIsLoading(false);
          setResponse("# Not available");
        });
  }, [url]);
  return { response, isLoading };
}

export function useAuthZ() {
  const [authZ, setAuthZ] = useState<IAuthZ[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  //let isLoading = true;
  useEffect(() => {
    fetchAuthZ().then((res) => {
      setIsLoading(false);
      setAuthZ(res);
    });
  }, []);
  return { authZ, isLoading };
}
export function useNotifications<TData extends BaseRecord = BaseRecord>() {
  return useTable<TData>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.WORKLIST,
    initialSorter: [
      {
        field: "stateManager.assignedAt",
        order: "desc",
      },
    ],
    syncWithLocation: false,
  });
}
export function parseTimeZone(userTime: string): string {
  let result = "";
  if (userTime) {
    const timeZoneText = userTime.match(/\(([A-Za-z\s].*)\)/);
    if (timeZoneText) {
      result = timeZoneText[1];
    }
  }
  return result;
}

export declare type TimeZoneResult = {
  text: string;
  relativeType: "None" | "Equal" | "Ahead" | "Behind";
};

export function parseRelativeTimeZone(
  userTime: string | undefined
): TimeZoneResult {
  const result: TimeZoneResult = {
    text: "",
    relativeType: "None",
  };
  if (userTime) {
    /* eslint-disable no-useless-escape */
    const userTimeZone = userTime.match(/([-\+][0-9]+)\s/);
    /* eslint-disable no-useless-escape */
    const currentTimeZone = new Date().toString().match(/([-\+][0-9]+)\s/);

    if (userTimeZone && currentTimeZone) {
      //const diff = parseInt(userTimeZone[0]) - parseInt(currentTimeZone[0]);
      const diff = parseInt(currentTimeZone[0]) - parseInt(userTimeZone[0]);
      const hourPart = Math.abs(diff).toString().slice(0, -2);
      const diffFormat =
        (hourPart ? hourPart + "h " : "") + diff.toString().slice(-2) + "m";
      if (diff === 0) {
        result.text = "Same time zone as you ";
        result.relativeType = "Equal";
      } else if (diff < 0) {
        result.text = "You're " + diffFormat + " behind ";
        result.relativeType = "Behind";
      }
      if (diff > 0) {
        result.text = "You're " + diffFormat + " ahead ";
        result.relativeType = "Ahead";
      }
    }
  }
  return result;
}

export function buildAttachmentUrl(
  resource: string,
  id: string | undefined,
  filepath: string | undefined
): string | undefined {
  if (resource && id && filepath) {
    return [ATTACHMENT, resource, id].join("/") + "?filepath=" + filepath;
  }
  return undefined;
}

// Update Local storage with forms
export function updateLocalStorageEaluationOptions(
  key: string,
  groupId: string,
  questionId: string,
  updateType: "options" | "comments" = "options",
  data: IOption[]
) {
  const modelFromStorage = getLocalStorage(key);
  if (modelFromStorage) {
    var modelToStore = modelFromStorage as IGroup[];
    var group = modelToStore.find((grp) => grp.id === groupId);
    if (group) {
      var question = group.questions.find((ques) => ques.id === questionId);
      if (question) {
        question.options = data;
      }
    }
    setLocalStorage(key, modelToStore);
  }
}

export function stateManagerToMermaid(stateManager: IStateManager): string {
  // Initialize with mermaid string
  let mermaidDiagram = "flowchart TB \n";
  let previousState = "";
  let stateDetails = "";

  try {
    const nodes = arrayExtensions.sortBy("beginTS", stateManager.workflow);

    nodes.forEach(function (node, indx) {
      stateDetails = node.stateChangedBy
        ? `Acted by: ${node.stateChangedBy} \n`
        : "";
      stateDetails += node.beginTS
        ? `on: ${dayjs(node.beginTS).format("LLLL")} \n`
        : "";
      stateDetails += arrayExtensions.validateArray(node.assignedTo)
        ? `assigned to: ${node.assignedTo.join(";")} \n`
        : "";
      stateDetails += node.comments ? `with comments: ${node.comments}` : "";

      // Process the stateManager Nodes
      if (indx === 0) {
        mermaidDiagram += `AA[[Entity Creation]]:::node --> |"${stateDetails}"| ${getNextAlphabet(
          indx
        )}((${stringExtensions.capitalize(node.state)})) \n`;
      } else {
        mermaidDiagram += `${previousState}:::node --> |"${stateDetails}"| ${getNextAlphabet(
          indx
        )}((${stringExtensions.capitalize(node.state)})) \n`;
      }
      previousState = `${getNextAlphabet(indx)}((${stringExtensions.capitalize(
        node.state
      )}))`;
    });

    mermaidDiagram += `classDef node fill:#000, color:#fff; \n`;
  } catch (ex) {
    mermaidDiagram += `AA[[Error !!! Unable to generate mermaid view]]:::errnode \n`;
    mermaidDiagram += `classDef errnode fill:#ff0000, color:#fff; \n`;
  }

  return mermaidDiagram;
}

export function statesToMermaid(states: IState[]): string {
  // Initialize with mermaid string
  let mermaidDiagram = "stateDiagram-v2 \n";
  mermaidDiagram += "direction LR \n";

  try {
    const nodes = arrayExtensions.sortArrayByOrder(states);

    nodes.forEach(function (node, indx) {
      // Process the state Nodes
      if (indx === 0) {
        mermaidDiagram += `[*] --> ${stringExtensions.capitalize(
          node.state
        )} \n`;
      } else if (node.nextStates && node.nextStates.length === 0) {
        mermaidDiagram += `${stringExtensions.capitalize(
          node.state
        )} --> [*] \n`;
      }

      // Draw for each possible state swicthes
      node.nextStates.forEach(function (st: nextState) {
        mermaidDiagram += `${stringExtensions.capitalize(
          node.state
        )} --> ${stringExtensions.capitalize(st.stateId)} : ${st.action} \n`;
      });
    });
  } catch (ex) {
    mermaidDiagram += `error: !!! Unable to generate mermaid view !!! \n`;
  }

  return mermaidDiagram;
}

export function getNextAlphabet(index: number, capital: boolean = true) {
  return String.fromCharCode(index + (capital ? 65 : 97));
}

export function getCookieByName(cookieName: string) {
  let name = cookieName + "=";
  let spli = document.cookie.split(";");

  for (var j = 0; j < spli.length; j++) {
    let char = spli[j];
    while (char.charAt(0) === " ") {
      char = char.substring(1);
    }
    if (char.indexOf(name) === 0) {
      return char.substring(name.length, char.length);
    }
  }
  return "";
}

export function roundToNearesetFive(roundNumber: number): number {
  if (roundNumber === 0) {
    return roundNumber;
  } else {
    return Math.round(roundNumber / 5.0) * 5;
  }
}

export function arrayJoinString(
  inputArray: string[],
  joinString: string = ", ",
  defaultString: string = "---"
): string {
  if (arrayExtensions.validateArray(inputArray)) {
    return inputArray.join(joinString);
  }
  return defaultString;
}

export function getGUID() {
  var uuidValue = "",
    k,
    randomValue;
  for (k = 0; k < 32; k++) {
    randomValue = (Math.random() * 16) | 0;

    if (k === 8 || k === 12 || k === 16 || k === 20) {
      uuidValue += "-";
    }
    uuidValue += (
      k === 12 ? 4 : k === 16 ? (randomValue & 3) | 8 : randomValue
    ).toString(16);
  }
  return uuidValue;
}

export function formatMS(
  ms: number,
  trunc: boolean = true,
  precise: boolean = true,
  limit: number = 10
) {
  const long: string[] = [
    "millisecond",
    "second",
    "minute",
    "hour",
    "day",
    "year",
  ];
  const short: string[] = ["ms", "sec", "min", "hr", "day", "year"];
  const calcs: number[] = [1000, 60, 60, 24, 365];
  let formatString: string = "";
  let i: number;

  //Calcs
  for (i = 0; ms / calcs[i] > 1 && i < limit; i++) {
    ms /= calcs[i];
  }

  precise ? (formatString += ms.toFixed(2)) : (formatString += ms.toFixed(0));
  formatString += " ";
  trunc ? (formatString += short[i]) : (formatString += long[i]);
  if (!precise && ms.toFixed(0) !== "1") formatString += "s";
  if (precise && ms.toFixed(2) !== "1.00") formatString += "s";
  return formatString;
}

export function timeDiff(
  x: string,
  y: string,
  trunc: boolean = true,
  precise: boolean = true,
  limit: number = 10
) {
  let day1 = dayjs(Date.now());
  let day2 = dayjs(Date.now());
  let diff: number;

  //Default to Current Date/Time if empty
  if (x !== "") day1 = dayjs(x);
  if (y !== "") day2 = dayjs(y);

  //Default to always positive
  if (dayjs(x) > dayjs(y)) {
    diff = day1.diff(day2, "ms", true);
  } else {
    diff = day2.diff(day1, "ms", true);
  }

  return formatMS(diff, trunc, precise, limit);
}
