import { IBaseProps, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Button, getDefaultSortOrder, Icons, RefreshButton, Show, Table, Tag, TextField, Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps, useNavigation } from "@pankod/refine-core";
import { StateButtons } from "components/common/stateButtons";
import { RenderTimelineModal } from "components/common/timelineModal";
import { ShowUserCard } from "components/profile/showUserCard";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_STATE, RESOURCE_PATH, useNotifications } from "scripts/site";

export const Worklist: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useNotifications();
  const { edit, show } = useNavigation();

  function rowClick(record: any) {
    return {
      onClick: () => {
        show(record?.type, record?.id);
      },
    };
  }

  return (
    <Show
      title="Worklist"
      canDelete={false}
      pageHeaderProps={{
        backIcon: false,
        tags: <Tag color={"green"}>FYA Notifications</Tag>,
        extra: <><RefreshButton resource={RESOURCE_PATH.WORKLIST} dataProviderName={DATAPROVIDER_LOOKUP}></RefreshButton></>
      }}
    >
      <Table {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total} action item(s)</Typography.Title>)
        }}
      >

        <Table.Column
          dataIndex={["stateManager", "workflowTitle"]}
          key="stateManager.workflowTitle"
          title="Title"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("stateManager.workflowTitle", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="type"
          key="type"
          title="Type"
          render={(value) => <TextField value={stringExtensions.capitalize(value)} />}
          defaultSortOrder={getDefaultSortOrder("type", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />


        <Table.Column
          dataIndex={["stateManager", "state"]}
          key="state"
          title="State"
          render={(value) => <TextField value={stringExtensions.capitalize(value)} />}
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="updatedAt"
          key="updatedAt"
          title="Last Updated"
          render={displayRelativeDate}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />
        <Table.Column
          dataIndex="updatedBy"
          key="updatedBy"
          title="Assigned By"
          render={(value: string) =>
            <ShowUserCard id={value} />
          }
          defaultSortOrder={getDefaultSortOrder("updatedBy", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          title="Actions"
          key="actions"
          fixed="right"
          className="wrklstbtns"
          render={(v, record: any) => (
            <>
              <Button
                type="primary"
                onClick={() => {
                  edit(record.type, record.id);
                }}
              >
                <Icons.IssuesCloseOutlined />
                Review
              </Button>

              <StateButtons {...record as IBaseProps}
                resourceToAct={record.type}
                resourceToActId={record?.id || "0"}
                navigateToId={""}
                resourceToNavigate={RESOURCE_PATH.PROFILE}
                hideActions={['submit', 'reject', 'approve', 'clientapproval']}
                fetchAssignedToList={false}
                dataProviderName={DATAPROVIDER_STATE}
                mode={"worklist"}
              />
              <RenderTimelineModal
                {...record.stateManager}
              />
            </>
          )}
        />
      </Table>
    </Show>
  );
};
