import { ILookup, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Button, Col, Form, FormProps, Icons, Input, Row, Select, Spin, useSelect } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { IUserMetadata } from "interfaces";
import { useState } from "react";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH } from "scripts/site";

export const CaseFilter: React.FC<{ formProps: FormProps }> = (props) => {
    const { isLoading, data: metadata } = useOne<IUserMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.CASE,
        queryOptions: {
            enabled: true,
            staleTime: 300000
        }
    });


    const { selectProps: userProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PROFILE,
        optionLabel: "nameEmail",
        optionValue: "id",
        sort: [{
            field: "lastAccessedAt",
            order: "desc"
        }]
    });

    const { selectProps: programProps, queryResult: programQuery } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PROGRAM,
        optionLabel: "name",
        optionValue: "id",
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        queryOptions: {
            //enabled: (lineOfBusiness !== undefined && lineOfBusiness !== ""),
            //staleTime: 300000,
        }
    });
    const [program, setProgram] = useState("");

    const { selectProps: caseTypeProps, queryResult: caseTypeQuery } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.CASE_TYPE,
        optionLabel: "name",
        optionValue: "id",
        filters: [{
            field: "parentId",
            operator: "eq",
            value: program
        }],
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        queryOptions: {
            //enabled: (program !== undefined && program !== ""),
            //staleTime: 300000,
        }
    });
    const [caseType, setCaseType] = useState("");

    const { selectProps: subCaseTypeProps, queryResult: subCaseTypeQuery } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.SUB_CASE_TYPE,
        optionLabel: "name",
        optionValue: "id",
        filters: [{
            field: "parentId",
            operator: "eq",
            value: caseType
        }],
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        queryOptions: {
            //enabled: (program !== undefined && program !== ""),
            //staleTime: 300000,
        }
    });
    const [subCaseType, setSubCaseType] = useState("");

    const { selectProps: caseReasonProps, queryResult: caseReasonQuery } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.CASE_REASON,
        optionLabel: "name",
        optionValue: "id",
        filters: [{
            field: "parentId",
            operator: "eq",
            value: subCaseType
        }],
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        queryOptions: {
            //enabled: (program !== undefined && program !== ""),
            //staleTime: 300000,
        }
    });
    const [caseReason, setCaseReason] = useState("");

    const { selectProps: subReasonProps, queryResult: subReasonQuery } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.SUB_REASON,
        optionLabel: "name",
        optionValue: "id",
        filters: [{
            field: "parentId",
            operator: "eq",
            value: caseReason
        }],
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        queryOptions: {
            //enabled: (program !== undefined && program !== ""),
            //staleTime: 300000,
        }
    });

    const metaConfig = metadata?.data.config;
    return (
        isLoading ? <RenderSpinner message="Loading..." ></RenderSpinner> :
            <Form layout="vertical" {...props.formProps} autoComplete="off">
                <Row gutter={[10, 0]} align="bottom">
                    <Col xs={24} xl={24} md={12}>
                        <Form.Item
                            label="Search"
                            name="q"
                            tooltip={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Cases"}
                        >
                            <Input
                                placeholder={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Cases"}
                                prefix={<Icons.SearchOutlined />}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="Case state"
                            name="state"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Case state"
                            >
                                {metadata?.data?.states.map((m, i) => (
                                    <Select.Option value={m.state} key={i}>{stringExtensions.capitalize(m.state)}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="Assigned to"
                            name="assignedTo">
                            <Select mode="multiple" placeholder="Search Name or Email" {...userProps} allowClear />
                        </Form.Item>
                    </Col>

                    <Col xs={24} xl={24} md={8}>
                        <Spin spinning={programQuery.isRefetching || programQuery.isLoading} className="field-loading-spinner">
                            <Form.Item
                                label="Program"
                                name="programId">
                                {!(programQuery.isRefetching || programQuery.isLoading) &&
                                    <Select
                                        allowClear
                                        placeholder="Program"
                                        //mode="multiple"
                                        {...programProps}
                                        onChange={(val: any) => {
                                            setProgram(val);
                                            setCaseType("");
                                            setSubCaseType("");
                                            setCaseReason("");
                                        }}
                                    />}
                            </Form.Item>
                        </Spin>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        {program &&
                            <Spin spinning={caseTypeQuery.isRefetching || caseTypeQuery.isLoading} className="field-loading-spinner">
                                <Form.Item
                                    label="Case Type"
                                    name="caseTypeId"
                                >
                                    {!(caseTypeQuery.isRefetching || caseTypeQuery.isLoading) &&
                                        <Select
                                            allowClear
                                            placeholder="Case Type"
                                            {...caseTypeProps}
                                            onChange={(val: any) => {
                                                setCaseType(val);
                                                setSubCaseType("");
                                                setCaseReason("");
                                            }}
                                        />}
                                </Form.Item>
                            </Spin>
                        }
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        {caseType &&
                            <Spin spinning={subCaseTypeQuery.isRefetching || subCaseTypeQuery.isLoading} className="field-loading-spinner">
                                <Form.Item
                                    label="Sub Case Type"
                                    name="subCaseTypeId"

                                >
                                    {!(subCaseTypeQuery.isRefetching || subCaseTypeQuery.isLoading) &&
                                        <Select
                                            allowClear
                                            placeholder="Sub Case Type"
                                            {...subCaseTypeProps}
                                            onChange={(val: any) => {
                                                setSubCaseType(val);
                                                setCaseReason("");
                                            }}
                                        />
                                    }
                                </Form.Item>
                            </Spin>
                        }
                    </Col>

                    <Col xs={24} xl={24} md={8}>
                        {subCaseType &&
                            <Spin spinning={caseReasonQuery.isRefetching || caseReasonQuery.isLoading} className="field-loading-spinner">
                                <Form.Item
                                    label="Case Reason"
                                    name="caseReasonId"
                                >
                                    {!(caseReasonQuery.isRefetching || caseReasonQuery.isLoading) &&
                                        <Select
                                            allowClear
                                            placeholder="Case Reason"
                                            {...caseReasonProps}
                                            onChange={(val: any) => {
                                                setCaseReason(val);
                                            }}
                                        />}
                                </Form.Item>
                            </Spin>
                        }
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        {caseReason &&
                            <Spin spinning={subReasonQuery.isRefetching || subReasonQuery.isLoading} className="field-loading-spinner">
                                <Form.Item
                                    label="Sub Reason"
                                    name="subReasonId"
                                >
                                    {!(subReasonQuery.isRefetching || subReasonQuery.isLoading) &&
                                        <Select
                                            allowClear
                                            placeholder="Sub Reason"
                                            {...subReasonProps}
                                        />}
                                </Form.Item>
                            </Spin>
                        }
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                htmlType="submit"
                                type="primary"
                            >
                                Apply Filter(s)
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                htmlType="submit"
                                onClick={() => {
                                    props.formProps.form?.resetFields();
                                    setProgram("");
                                    setCaseType("");
                                    setSubCaseType("");
                                    setCaseReason("");
                                }}
                                type="ghost"
                            >
                                Clear Filter(s)
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
    );
}