import { arrayExtensions, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Col, DeleteButton, Divider, EditButton, ListButton, RefreshButton, Row, Show, Space, Tag, Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity, useList, useShow } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { ShowUserCard } from "components/profile/showUserCard";
import { ObjectMetadata } from "components/utils/objectMetadata";
import { IBaseProps, IMemberGroup, IUser } from "interfaces";
import { GroupType } from "interfaces/enum";
import { arrayJoinString, DATAPROVIDER_READ, FETCH_ACTION } from "scripts/site";

export const GroupShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IMemberGroup>({
    dataProviderName: DATAPROVIDER_READ
  });

  const { data: userData } = useGetIdentity();
  const userObj = userData as IUser;
  const { data, isLoading } = queryResult;
  const { Title, Text } = Typography;
  const record = data?.data as IMemberGroup;

  const { data: dynamicMembers, isLoading: isMembersLoading } = useList<string>({
    dataProviderName: DATAPROVIDER_READ,
    resource: `group/${record?.id}/${FETCH_ACTION}`,
    config: {
      filters: [
        {
          field: "operation",
          operator: "eq",
          value: "groupMembers"
        }
      ]
    },
    queryOptions: {
      enabled: (!isLoading && record.groupType === GroupType.Dynamic)
    }
  });
  const groupMembers = record?.groupType === GroupType.Static ? record.members : dynamicMembers?.data;

  return (
    <Show
      isLoading={isLoading}
      title={
        <>
          {record?.name}
          {record?.stateManager?.state ? <Tag color="green">{stringExtensions.capitalize(record?.stateManager?.state)}</Tag> : <></>}
        </>
      }
      headerButtons={
        () => (
          <>
            <ListButton></ListButton>
            {record?.admins?.find(x => x === userObj.id) ?
              <>
                <EditButton></EditButton>
                <DeleteButton></DeleteButton>
              </>
              : <></>
            }
            <RefreshButton></RefreshButton>
          </>
        )
      }
    >
      <Divider></Divider>
      <Row gutter={[16, 16]}>
        <Col xl={12} xs={24}>
          <Title level={5}>Group Name</Title>
          <Text>{record?.name}</Text>
          <Title level={5}>Group Type</Title>
          <Text>{stringExtensions.capitalize(record?.groupType)}</Text>
          <Title level={5}>Group Admins</Title>
          <Space wrap split={<Divider type="vertical" />}>
            {arrayExtensions.validateArray(record?.admins ?? []) ? record?.admins?.map((e, i) => <ShowUserCard key={`sales-${i}`} id={e}></ShowUserCard>) : "-- None --"}
          </Space>
        </Col>
        <Col xl={12} lg={24} xs={24}>
          <Title level={5}>Group Members</Title>
          {isMembersLoading ? <RenderSpinner message="Loading Members ..." /> :
            <Space wrap split={<Divider type="vertical" />}>
              <Text copyable disabled>{groupMembers ? arrayJoinString(groupMembers, "; ") : "No Members added to this group"}</Text>
            </Space>
          }
          <div>
            <Tag style={{ marginTop: 6 }} color="blue">Please use copy icon to copy the members list</Tag>
          </div>
        </Col>
      </Row>
      <Divider></Divider>
      <div style={{ marginTop: 15 }}>
        <ObjectMetadata {...record as IBaseProps} ></ObjectMetadata>
      </div>
    </Show>
  );
};
