import {
  Create, Form, Input, SaveButton, useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { CaseFieldsRadio } from "components/case/caseFieldsRadio";
import { CancelButton } from "components/utils/cancelButton";
import { ICase } from "interfaces";
import { DATAPROVIDER_CREATE } from "scripts/site";

export const CaseCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, form } = useForm<ICase>({
    dataProviderName: DATAPROVIDER_CREATE,
    redirect: "list"
  });

  return (
    <Create
      footerButtons={() => (
        <>
          <SaveButton {...saveButtonProps} />
          <CancelButton />
        </>
      )}>

      <Form {...formProps} layout="vertical" autoComplete="off">
        <Form.Item
          label="Case Title"
          name="name"
          rules={[
            {
              required: true,
              whitespace: true,
              max: 100
            },
          ]}
        >
          <Input />
        </Form.Item>
        <CaseFieldsRadio isLoading={queryResult?.isLoading ?? true} form={form}>
        </CaseFieldsRadio>
        <Form.Item
          label="Notes"
          name="notes">
          <Input.TextArea rows={3} placeholder="Optional notes" />
        </Form.Item>
      </Form>
    </Create>
  );
};
