import { Edit, Form, Input, SaveButton, useForm } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { CaseFieldsRadio } from "components/case/caseFieldsRadio";
import { CancelButton } from "components/utils/cancelButton";
import { ICase } from "interfaces";
import { DATAPROVIDER_UPDATE } from "scripts/site";

export const CaseEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, form } = useForm<ICase>({
    dataProviderName: DATAPROVIDER_UPDATE
  });

  const record = queryResult?.data?.data as ICase;

  return (
    <Edit
      footerButtons={() => (
        <>
          <SaveButton  {...saveButtonProps} />
          <CancelButton />
        </>
      )}
    >
      <Form {...formProps} layout="vertical" autoComplete="off">
        <Form.Item
          label="Case Title"
          name="name"
          rules={[
            {
              required: true,
              whitespace: true,
              max: 100
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Notes"
          name="notes">
          <Input.TextArea rows={3} placeholder="Optional notes" />
        </Form.Item>
        <CaseFieldsRadio record={record} isLoading={queryResult?.isLoading ?? true} form={form}>
        </CaseFieldsRadio>
      </Form>
    </Edit>
  );
};
